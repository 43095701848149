import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import MenuRoundedIcon from "@material-ui/icons/MenuRounded"
import CloseRoundedIcon from "@material-ui/icons/CloseRounded"
import IconButton from "@material-ui/core/IconButton"
import Modal from "@material-ui/core/Modal"
import Slide from "@material-ui/core/Slide"
import clsx from "clsx"

import MenuList from "./menuList"

import useMuiState from "../../hooks/useMuiState"

const useStyles = makeStyles(({ palette, spacing, shape, shadows }) => ({
  root: {
    position: "absolute",
    top: spacing(3),
    right: spacing(3),
    padding: spacing(1),
    ...shape,
    zIndex: 10,
  },
  open: {
    backgroundColor: palette.primary.main,
    "&:hover, &:focus": {
      backgroundColor: palette.primary.dark,
    },
  },
  close: {
    backgroundColor: palette.secondary.main,
    "&:hover, &:focus": {
      backgroundColor: palette.secondary.light,
    },
  },
  paper: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: palette.primary.main,
    border: 0,
    padding: spacing(2, 4, 1),
    outline: 0,
    display: "flex",
    flexDirection: "column",
  },
}))

const MobileMenu = () => {
  const classes = useStyles()
  const [handleOpen, handleClose, open] = useMuiState(false)

  return (
    <>
      <IconButton
        disableFocusRipple
        aria-label="open-menu"
        color="secondary"
        className={clsx(classes.root, classes.open)}
        onClick={handleOpen}
      >
        <MenuRoundedIcon />
      </IconButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="open-menu"
        aria-describedby="mobile-menu"
      >
        <Slide in={open}>
          <div className={classes.paper}>
            <IconButton
              disableFocusRipple
              aria-label="close-menu"
              color="primary"
              className={clsx(classes.root, classes.close)}
              onClick={handleClose}
            >
              <CloseRoundedIcon />
            </IconButton>
            <MenuList mobile closeMenu={handleClose} />
          </div>
        </Slide>
      </Modal>
    </>
  )
}

export default MobileMenu
