// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-bedankt-jsx": () => import("./../../src/pages/bedankt.jsx" /* webpackChunkName: "component---src-pages-bedankt-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-over-mij-jsx": () => import("./../../src/pages/over-mij.jsx" /* webpackChunkName: "component---src-pages-over-mij-jsx" */),
  "component---src-templates-category-jsx": () => import("./../../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */)
}

