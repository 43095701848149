import React from "react"
import { makeStyles } from "@material-ui/core/styles"

import DesktopMenu from "./desktopMenu"
import MobileMenu from "./mobileMenu"

const useStyles = makeStyles(({ breakpoints }) => ({
  desktop: {
    display: "none",
    [breakpoints.up("md")]: {
      display: "block",
    },
  },
  mobile: {
    display: "none",
    [breakpoints.down("sm")]: {
      display: "block",
    },
  },
}))

const MenuController = () => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.desktop}>
        <DesktopMenu />
      </div>
      <div className={classes.mobile}>
        <MobileMenu />
      </div>
    </>
  )
}

export default MenuController
