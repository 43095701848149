import { useState } from "react"

const useMuiState = initState => {
  const [open, setOpen] = useState(initState)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return [handleOpen, handleClose, open]
}

export default useMuiState
