import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import MenuList from "./menuList"

const drawerWidth = 320

const useStyles = makeStyles(({ palette }) => ({
  drawer: {
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: palette.primary.main,
    borderRight: `2px solid ${palette.secondary.main}`,
  },
}))

const DesktopMenu = () => {
  const classes = useStyles()
  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <MenuList />
    </Drawer>
  )
}

export default DesktopMenu
