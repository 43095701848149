import React from "react"
import MenuController from "./menu/menuController"

const Page = ({ children }) => (
  <div style={{ display: "flex", width: "100%" }}>
    <MenuController />
    {children}
  </div>
)

export default Page
