import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { makeStyles } from "@material-ui/core/styles"
import MenuItem from "@material-ui/core/MenuItem"
import MuiMenuList from "@material-ui/core/MenuList"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"

// ...GatsbyImageSharpFixed_withWebp

const useStyles = makeStyles(({ palette, spacing, shape }) => ({
  mobile: {
    margin: spacing(2),
    marginLeft: spacing(3.5),
  },
  desktop: {
    margin: `${spacing(6)}px auto ${spacing(2)}px`,
    transform: "translateX(-5px)",
  },
  creatorLink: {
    color: palette.secondary.main,
    "&:hover, &:focus": {
      color: palette.secondary.light,
    },
    textDecoration: "none",
    transition: "all ease-in-out 0.3s",
  },
  bottom: {
    margin: `auto auto ${spacing(1)}px`,
    display: "flex",
    flexDirection: "column",
  },
  social: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  link: {
    letterSpacing: "0.12em",
    fontWeight: 900,
    marginLeft: spacing(1.5),
  },
  subLink: {
    letterSpacing: "0.1em",
    fontWeight: 400,
    marginLeft: spacing(4),
  },
  divider: {
    margin: spacing(4, 3),
    backgroundColor: palette.secondary.main,
  },
}))

const MenuList = ({ mobile, closeMenu }) => {
  const data = useStaticQuery(graphql`
    query menu {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(height: 80) {
            ...GatsbyImageSharpFixed
          }
        }
        publicURL
      }
      markdownRemark(frontmatter: { id: { eq: "menu" } }) {
        frontmatter {
          menuItems {
            displayName
            link
          }
        }
      }
      allMarkdownRemark(
        filter: { frontmatter: { type: { eq: "portfolio-collection" } } }
      ) {
        nodes {
          frontmatter {
            category
            path
          }
        }
      }
    }
  `)

  const { menuItems } = data.markdownRemark.frontmatter // get all the menu data from cms
  const { fixed } = data.file.childImageSharp // img for gatsby img
  const categories = data.allMarkdownRemark.nodes
  const classes = useStyles()

  return (
    <>
      <Img
        fixed={fixed}
        className={mobile ? classes.mobile : classes.desktop}
      />

      <Divider className={classes.divider} />

      <MuiMenuList>
        <MenuButton
          item={{ link: "/", displayName: "portfolio" }}
          className={classes.link}
          onClick={closeMenu}
        />

        {categories.map(({ frontmatter: { category, path } }) => (
          <MenuButton
            key={path}
            item={{ link: path, displayName: category }}
            className={classes.subLink}
            onClick={closeMenu}
          />
        ))}

        {menuItems.map(item => (
          <MenuButton
            key={item.link}
            item={item}
            className={classes.link}
            onClick={closeMenu}
          />
        ))}
      </MuiMenuList>

      <div className={classes.bottom}>
        <div className={classes.social}>
          <IconButton
            color="secondary"
            component="a"
            href="https://www.facebook.com/Photography-by-Audrey-107997000599150"
            rel="noreferrer noopener"
            target="_blank"
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            color="secondary"
            component="a"
            href="https://www.instagram.com/photographybyaudreym/?hl=nl"
            rel="noreferrer noopener"
            target="_blank"
          >
            <InstagramIcon />
          </IconButton>
        </div>

        <Typography
          component="a"
          variant="subtitle2"
          href="https://www.mddd.nl"
          className={classes.creatorLink}
          rel="author noreferrer noopener"
          target="_blank"
        >
          M.D. Design &amp; Development © {new Date().getFullYear()}
        </Typography>
      </div>
    </>
  )
}

const MenuButton = ({ item, className, onClick }) => (
  <MenuItem key={item.link} component={Link} to={item.link} onClick={onClick}>
    <Typography
      color="secondary"
      variant="h6"
      children={item.displayName.toLowerCase()}
      className={className}
    />
  </MenuItem>
)

export default MenuList
